import { inject, Injectable } from '@angular/core';
import Dexie from 'dexie';

import {
    ControllerProperties,
    ControllerProtocolSettings,
    DaliDevice,
    Datasheet,
    Element,
    ElementBindingStatus,
    ElementFilter,
    HardwareRegistration,
    IOPinProtocolMapping,
    Patch,
    Riom,
    Solution,
    TerminalUnitModel,
    Topology,
    ZoneDetail,
} from '@priva/next-model';

import { DEXIE_CONFIG, DexieConfiguration } from './provider/dexie.config';

// TODO: WvK: Refactor into multiple storage services

@Injectable({
    providedIn: 'root',
})
export class NextLocalStorageService {
    private db = inject(Dexie);
    private dexieConfiguration = inject<DexieConfiguration>(DEXIE_CONFIG);

    constructor() {
        this.initDB();
    }

    /* istanbul ignore next wvk, simple return */
    public async getSolutions(): Promise<Solution[]> {
        return this.db.table('solution').toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async hasSolutions(): Promise<boolean> {
        const count = await this.db.table('solution').count();
        return count > 0;
    }

    /* istanbul ignore next wvk, simple return */
    public async getSolution(id: string): Promise<Solution> {
        return this.db.table('solution').where({ id }).first();
    }

    /* istanbul ignore next wvk, hard to test */
    public async getTerminalUnitModels(): Promise<TerminalUnitModel[]> {
        return this.db.table('terminalUnitModels').toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async getTopology(solutionId: string): Promise<Topology> {
        return this.db.table('topology').where({ solutionId }).first();
    }

    /* istanbul ignore next wvk, simple return */
    public async getZone(solutionId: string, zoneId: string): Promise<ZoneDetail> {
        return this.db
            .table('zone')
            .where({ solutionId })
            .filter((z) => z.id === zoneId)
            .first();
    }

    /* istanbul ignore next wvk, simple return */
    public async getElement(solutionId: string, elementId: string): Promise<Element> {
        return this.db.table('element').where({ solutionId, id: elementId }).first();
    }

    /* istanbul ignore next wvk, simple return */
    public async getRiom(solutionId: string, elementId: string): Promise<Riom> {
        return this.db.table('riom').where({ solutionId, id: elementId }).first();
    }

    public async updateRiomIOPinValidation(
        solutionId: string,
        riomId: string,
        ioPinProtocolMapping: IOPinProtocolMapping,
    ): Promise<unknown> {
        const riom = await this.getRiom(solutionId, riomId);
        const ioPin = riom.ioPins.find((p) => p.protocolMapping?.id === ioPinProtocolMapping.id);
        ioPin.protocolMapping = { ...ioPin.protocolMapping, ...ioPinProtocolMapping };
        return this.db.table('riom').update({ solutionId, id: riom.id }, riom);
    }

    /* istanbul ignore next wvk, hard to test */
    public async getElements(solutionId: string, filter?: ElementFilter): Promise<Element[]> {
        const tagMatcher = (d: Element) =>
            !filter?.type || filter?.type.some((t) => d.model.types?.includes(t));

        const modelMatcher = (d: Element) => !filter?.model_id || d.model.id === filter.model_id;

        const elementFilter = (predicates: ((d: Element) => boolean)[]) => (d: Element) =>
            predicates.reduce((result, predicate) => result && predicate(d), true);
        return this.db
            .table('element')
            .where({ solutionId })
            .filter(elementFilter([tagMatcher, modelMatcher]))
            .toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async getDaliDevices(solutionId: string) {
        return this.db.table('daliDevices').where({ solutionId }).toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async getZoneElements(solutionId: string, zoneId: string): Promise<Element[]> {
        return this.db
            .table('element')
            .where({ solutionId })
            .filter((d: Element) => {
                return d.zone.id === zoneId;
            })
            .toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async getHardwareRegistrations(solutionId: string): Promise<HardwareRegistration[]> {
        return this.db.table('hardwareRegistration').where({ solutionId }).toArray();
    }

    public async deleteHardwareRegistration(solutionId: string, elementId: string): Promise<unknown> {
        return this.db.table('hardwareRegistration').where({ solutionId, elementId }).delete();
    }

    /* istanbul ignore next wvk, simple return */
    public async getHardwareRegistration(
        solutionId: string,
        elementId: string,
    ): Promise<HardwareRegistration> {
        return this.db.table('hardwareRegistration').where({ solutionId, elementId }).first();
    }

    /* istanbul ignore next bw, simple return */
    public async getControllerProperties(
        solutionId: string,
        elementId: string,
    ): Promise<ControllerProperties> {
        return this.db.table('controllerProperties').where({ solutionId, elementId }).first();
    }

    /* istanbul ignore next bw, simple return */
    public async getControllersProperties(solutionId: string): Promise<ControllerProperties[]> {
        return this.db.table('controllerProperties').where({ solutionId }).toArray();
    }

    /* istanbul ignore next bw, simple return */
    public async getDatasheets(solutionId: string): Promise<Datasheet[]> {
        return this.db.table('datasheets').where({ solutionId }).toArray();
    }

    /* istanbul ignore next bw, simple return */
    public async getElementBindingStatus(
        solutionId: string,
        elementId: string,
    ): Promise<ElementBindingStatus> {
        return this.db.table('elementBindingStatus').where({ solutionId, elementId }).first();
    }

    /* istanbul ignore next bw, simple return */
    public async getControllerProtocolSettings(
        solutionId: string,
        elementId: string,
    ): Promise<ControllerProtocolSettings> {
        return this.db.table('controllerProtocolSettings').where({ solutionId, id: elementId }).first();
    }

    /* istanbul ignore next wvk, simple return */
    public async putSolution(solution: Solution): Promise<unknown> {
        return this.db.table('solution').put({ ...solution, offline: true });
    }

    /* istanbul ignore next wvk, hard to test */
    public async clear(solution: Solution): Promise<void> {
        await this.deleteSolution(solution.id);
        await this.db.table('terminalUnitModels').clear();
        await this.delete('topology', solution.id);
        await this.delete('riom', solution.id);
        await this.delete('hardwareRegistration', solution.id);
        await this.delete('controllerProperties', solution.id);
        await this.delete('elementBindingStatus', solution.id);
        await this.delete('daliDevices', solution.id);
        await this.db.table('latestLibraries').clear();
        await this.delete('datasheets', solution.id);
        await this.delete('controllerProtocolSettings', solution.id);
        await this.delete('element', solution.id);
    }

    /* istanbul ignore next wvk, simple return */
    public async putModels(models: TerminalUnitModel[]): Promise<unknown> {
        const modelsPut = models.map((m) => ({ id: m.id, ...m }));
        return this.db.table('terminalUnitModels').bulkPut(modelsPut);
    }

    /* istanbul ignore next wvk, hard to test */
    public async updateElement(solutionId: string, elementPatch: Patch): Promise<unknown> {
        const currentElement = await this.getElement(solutionId, elementPatch.id);
        const element: Element = {
            ...currentElement,
            ...elementPatch,
        };
        return this.db.table('element').update({ solutionId, id: elementPatch.id }, element);
    }

    /* istanbul ignore next wvk, simple return */
    public async deleteSolution(solutionId: string): Promise<number> {
        return this.db.table('solution').where({ id: solutionId }).delete();
    }

    /* istanbul ignore next wvk, simple return */
    public async putTopology(solutionId: string, topology: Topology): Promise<unknown> {
        return this.db.table('topology').put({ solutionId, ...topology });
    }

    /* istanbul ignore next wvk, simple return */
    public async putElements(solutionId: string, elements: Element[]): Promise<unknown> {
        const elementsPut = elements.map((d) => ({ solutionId, ...d }));
        return this.db.table('element').bulkPut(elementsPut);
    }

    /* istanbul ignore next wvk, simple return */
    public async putHardwareRegistrations(
        solutionId: string,
        hardwareRegistrations: HardwareRegistration[],
    ): Promise<unknown> {
        const hardwareRegistrationsPut = hardwareRegistrations.map((r) => ({ solutionId, ...r }));
        return this.db.table('hardwareRegistration').bulkPut(hardwareRegistrationsPut);
    }

    /* istanbul ignore next bw, simple return */
    public async putControllerProperties(
        solutionId: string,
        controllerProperties: ControllerProperties[],
    ): Promise<unknown> {
        const controllerPropertiesPut = controllerProperties.map((p) => ({ solutionId, ...p }));
        return this.db.table('controllerProperties').bulkPut(controllerPropertiesPut);
    }

    /* istanbul ignore next bw, simple return */
    public async putControllerProtocolSettings(
        solutionId: string,
        controllerProtocolSettings: ControllerProtocolSettings[],
    ): Promise<unknown> {
        const controllerProtocolSettingsPut = controllerProtocolSettings.map((s) => ({ solutionId, ...s }));
        return this.db.table('controllerProtocolSettings').bulkPut(controllerProtocolSettingsPut);
    }

    /* istanbul ignore next bw, simple return */
    public async putElementBindingStatus(
        solutionId: string,
        elementBindingStatus: ElementBindingStatus[],
    ): Promise<unknown> {
        const elementBindingStatusPut = elementBindingStatus.map((s: ElementBindingStatus) => ({
            solutionId,
            ...s,
        }));
        return this.db.table('elementBindingStatus').bulkPut(elementBindingStatusPut);
    }

    /* istanbul ignore next wvk, simple return */
    public async putDaliDevices(solutionId: string, daliDevices: DaliDevice[]): Promise<unknown> {
        const daliDevicesPut = daliDevices.map((d: DaliDevice) => ({ solutionId, ...d }));
        return this.db.table('daliDevices').bulkPut(daliDevicesPut);
    }

    /* istanbul ignore next wvk, simple return */
    public async putDatasheets(solutionId: string, datasheets: Datasheet[]): Promise<unknown> {
        const datasheetsPut = datasheets.map((d) => ({ solutionId, d }));
        return this.db.table('datasheets').bulkPut(datasheetsPut);
    }

    /* istanbul ignore next bw, simple return */
    public async updateControllerProperties(
        solutionId: string,
        elementId: string,
        controllerProperties: ControllerProperties,
    ): Promise<unknown> {
        const currentControllerProperties = await this.db
            .table('controllerProperties')
            .where({ solutionId, elementId })
            .first();
        const controllerPropertiesPatch = { ...currentControllerProperties, ...controllerProperties };
        return this.db
            .table('controllerProperties')
            .update({ solutionId, elementId }, controllerPropertiesPatch);
    }

    /* istanbul ignore next wvk, simple return */
    public async putRioms(solutionId: string, rioms: Riom[]): Promise<unknown> {
        const riomsPut = rioms.map((riom) => ({ solutionId, ...riom }));
        return this.db.table('riom').bulkPut(riomsPut);
    }

    /* istanbul ignore next wvk, simple return */
    public async putZones(solutionId: string, zones: ZoneDetail[]): Promise<unknown> {
        const zonesPut = zones.map((z) => ({ solutionId, id: z.id, ...z }));
        return this.db.table('zone').bulkPut(zonesPut);
    }

    /* istanbul ignore next wvk, simple return */
    public async delete(entity: string, solutionId: string): Promise<void> {
        await this.db.table(entity).where({ solutionId }).delete();
    }

    /* istanbul ignore next wvk, simple return */
    public async exists(solutionId: string): Promise<boolean> {
        const cachedSolution = await this.db.table('solution').get({ id: solutionId });
        return !!cachedSolution;
    }

    /* istanbul ignore next wvk, simple return */
    public async isAutoSync(solutionId: string): Promise<boolean> {
        const cachedSolution = await this.db.table('solution').get({ id: solutionId });
        return (cachedSolution && cachedSolution.autoSync) || false;
    }

    /* istanbul ignore next wvk, just init */
    private initDB(): void {
        // Name only the indexed columns in the store definition
        this.db.version(this.dexieConfiguration.databaseVersion).stores({
            solution: 'id',
            terminalUnitModels: 'id',
            topology: 'solutionId',
            zone: 'id, solutionId, [solutionId+id]',
            element: 'id, solutionId, [solutionId+id]',
            riom: 'id, solutionId, [solutionId+id]',
            hardwareRegistration: 'solutionId, elementId, [solutionId+elementId]',
            controllerProperties: 'solutionId, elementId, [solutionId+elementId]',
            elementBindingStatus: 'solutionId, elementId, [solutionId+elementId]',
            daliDevices: 'solutionId',
            latestLibraries: 'id',
            datasheets: 'solutionId, id, [solutionId+id]',
            controllerProtocolSettings: 'solutionId, id, [solutionId+id]',
        });
    }
}
